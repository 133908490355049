var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-lg-12 col-md-12 col-sm-12" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "설비 목록",
                  columns: _vm.grid.columns,
                  gridHeight: _vm.grid.height,
                  data: _vm.grid.data,
                  filtering: false,
                  columnSetting: false,
                  usePaging: false,
                  isExcelDown: false,
                  editable: _vm.editable,
                  noDataLabel: _vm.$language(
                    "정비계획할 설비를 추가하세요. 저장할 시 정비결과를 등록 할 수 있습니다."
                  ),
                  selection: "multiple",
                  cardClass: "topcolor-orange",
                  rowKey: "minEquipmentMaintenanceId",
                },
                on: { linkClick: _vm.linkClick },
                scopedSlots: _vm._u([
                  {
                    key: "customArea",
                    fn: function ({ props }) {
                      return [
                        [
                          _c("q-btn", {
                            staticClass: "tableinnerBtn tableinnerUserSearch",
                            class: {
                              "tableinnerUserSearch-left": true,
                              "tableinnerUserSearch-right": true,
                            },
                            attrs: {
                              flat: "",
                              color: "blue-6",
                              label: props.row.sopMocName,
                              icon: "search",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.linkClick(props.row)
                              },
                            },
                          }),
                        ],
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: {
                                showLoading: false,
                                label: "추가",
                                icon: "add",
                              },
                              on: { btnClicked: _vm.add },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.grid.data.length > 0
                          ? _c("c-btn", {
                              attrs: {
                                showLoading: false,
                                label: "삭제",
                                icon: "remove",
                              },
                              on: { btnClicked: _vm.remove },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.grid.data.length > 0
                          ? _c("c-btn", {
                              attrs: {
                                url: _vm.insertUrl,
                                isSubmit: _vm.isSave,
                                param: _vm.grid.data,
                                mappingType: "POST",
                                label: "저장",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveInspection,
                                btnCallback: _vm.saveCallback,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }